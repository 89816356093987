// eslint-disable-next-line
import * as Sentry from '@sentry/browser' // "* as" is required!

import { settings } from 'settings'

const getParentElements = (el: HTMLElement) => {
  const parents = []
  while (el.parentElement) {
    parents.push(el.parentElement)
    el = el.parentElement
  }
  return parents
}

const getOwnText = (el: HTMLElement) => {
  let child = el.firstChild
  const texts = []

  while (child) {
    if (child.nodeType === Node.TEXT_NODE) {
      texts.push((child as CharacterData).data)
    }
    child = child.nextSibling
  }
  return texts.join('')
}

export const initSentry = () => {
  if (!settings.FRONTEND_SENTRY_DSN) {
    // Disable Sentry explicitly, just in case
    // (https://github.com/getsentry/sentry-javascript/blob/5.6.0/packages/types/src/options.ts#L14-L25)
    Sentry.init({
      enabled: false,
      integrations: (integrations) => [], //Disable all integrations
    })
    return
  }

  Sentry.init({
    dsn: settings.FRONTEND_SENTRY_DSN,
    release: `protosure@${settings.VERSION}`,
    beforeBreadcrumb: (breadcrumb, hint) => {
      // Remove annoying react warnings
      if (breadcrumb.message && breadcrumb.message.indexOf('UNSAFE_') > -1) return null
      if (breadcrumb.message && breadcrumb.message.indexOf('React.createFactory()') > -1) return null
      // Remove xhr noise
      if (breadcrumb.data?.url && breadcrumb.data?.url.indexOf('/session_security/ping/') > -1) return null
      if (breadcrumb.data?.url && breadcrumb.data?.url.indexOf('data/formulaFunctions.json') > -1) return null
      if (breadcrumb.data?.url && breadcrumb.data?.url.indexOf('data/excelFunctions.json') > -1) return null

      if (hint && hint.event instanceof MouseEvent) {
        const target = hint.event.target as HTMLElement
        const parentsText = getParentElements(target)
          .reverse()
          .map((e) => e.getAttribute('data-sentry'))
          .filter((text) => Boolean(text))
        const targetText = target.getAttribute('data-sentry') || getOwnText(target) || target.tagName.toLowerCase()

        const messageText = [...parentsText, targetText].join(' > ')
        breadcrumb.message = `[${breadcrumb.message}] ${messageText}`
      }
      return breadcrumb
    },
  })
}
