import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import jaTranslation from 'locales/ja/translation.json'

const resources = {
  ja: {
    translation: jaTranslation,
  },
}

const i18NextOptions = {
  detection: {
    // Language detection order:
    // 1. Query string
    // 2. Local storage
    // 3. Browser locale
    // TODO: Replace 'enDefaultLanguageDetector' with 'navigator' when the JA translations are ready
    order: ['querystring', 'localStorage', 'enDefaultLanguageDetector'], // , 'navigator'],

    // Save detected language in the local storage
    // (To make changes stick)
    caches: ['localStorage'],

    lookupQuerystring: 'lang',
  },

  resources,

  // Don't use any fallback translation, resort to the keys themselves instead.
  fallbackLng: false as const,

  // Disable separators to allow dots in keys.
  // For instance: t('Loading...') -- by default those dots would be treated as key separators.
  keySeparator: false as const,
  nsSeparator: false as const,

  // If the translation is an empty string, fall back to they key itself.
  returnEmptyString: false,

  interpolation: {
    // React is supposed to escape values and so we don't want to do it twice
    escapeValue: false,
  },

  react: {
    // i18next-parser is not aware of these settings and so instead of preserving tags like <br/>
    // it puts <1></1> into the key, and then the <Trans> tag cannot find the translation as it expects <br/>
    transSupportBasicHtmlNodes: false,
    transKeepBasicHtmlNodesFor: [],
  },
}

const enDefaultLanguageDetector = {
  name: 'enDefaultLanguageDetector',
  lookup: () => 'en',
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(enDefaultLanguageDetector)

export const i18nPromise = i18n.use(languageDetector).use(initReactI18next).init(i18NextOptions)

export const t = i18n.t.bind(i18n)

export const getCurrentLanguage = () => (i18n.language.substr(0, 2) === 'ja' ? 'ja' : 'en')
