require('./polyfill')

import React from 'react'
import { render } from 'react-dom'
import 'whatwg-fetch'

import { initSentry } from 'utils/sentry'
initSentry()

import 'font-awesome/css/font-awesome.css'
import 'styles/bootstrap.scss'
import 'styles/styles.scss'

import { i18nPromise, getCurrentLanguage } from 'i18nConfig'

i18nPromise.then(function () {
  // TODO: This should probably be set directly in html, and not just dynamically changed here?
  const htmlElement = document.getElementsByTagName('html')[0]
  htmlElement.setAttribute('lang', getCurrentLanguage())

  Promise.all([
    import('features/auth/components/AuthRequired'),
    import('widgets'),
    import('embedded/embeddedScriptsVersions'),
    import('./App'),
  ]).then((results) => {
    const { AuthRequired } = results[0]
    const { initWidgets } = results[1]
    const { loadEmbeddedScripts } = results[2]
    const { App } = results[3]

    initWidgets()
    loadEmbeddedScripts()

    render(<AuthRequired component={App} />, document.getElementById('root'))
  })
})
